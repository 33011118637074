/** @format */

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700|Open+Sans&display=swap");
@import url("//fonts.googleapis.com/earlyaccess/droidarabickufi.css");

/* @import "~react-image-gallery/styles/css/image-gallery.css"; */

* {
	box-sizing: border-box;
	margin: 0;
	padding: 0;
	/* font-family: "Helvetica", sans-serif; */
}

body {
	background-color: #f7f8fc;
}

:root {
	/* Primary Colors */
	--primary-color-blue: #1e88e5;
	--primary-color-light-blue: #e3f2fd;
	--primary-color-lightest-blue: #e8f5e9;
	--primary-color-dark-blue: #1565c0;
	--primary-color-darker-blue: #0d47a1;

	/* Secondary Colors */
	--secondary-color-pink: #f3e5f5;
	--secondary-color-light-pink: #ffebee;
	--secondary-color-lighter-pink: #ffe0e0;
	--secondary-color-dark-pink: #e91e63;
	--secondary-color-darker-pink: #d81b60;

	/* Accent Colors */
	--accent-color-yellow: #fffde7;
	--accent-color-orange: #ffcc80;
	--accent-color-green: #66bb6a;
	--accent-color-dark-green: #388e3c;
	--accent-color-purple: #b39ddb;
	--accent-color-dark-purple: #512da8;
	--accent-color-red: #ff5252;
	--accent-color-light-red: #ff8a80;

	/* Neutral Colors */
	--neutral-light-gray: #f7f8fc;
	--neutral-medium-gray: #cfcfcf;
	--neutral-dark-gray: #888888;
	--neutral-darker-gray: #707070;
	--neutral-lightest-gray: #ffffff;
	--neutral-darkest-gray: #3f3f3f;

	/* Text Colors */
	--text-color-primary: #000000;
	--text-color-secondary: #707070;
	--text-color-light: #f0f0f0;
	--text-color-dark: #333333;
	--text-color-accent: #1e88e5;

	/* Background Colors */
	--background-light: #f7f8fc;
	--background-dark: #3f3f3f;
	--background-accent-blue: #e3f2fd;
	--background-accent-yellow: #fffde7;

	/* Button Colors */
	--button-bg-primary: #1e88e5;
	--button-bg-primary-light: #e3f2fd;
	--button-bg-secondary: #f3e5f5;
	--button-font-color: #ffffff;
	--button-bg-accent: #ffcc80;

	/* Border Colors */
	--border-color-light: #e0e0e0;
	--border-color-dark: #707070;

	/* Badge Colors */
	--badge-bg-blue: #1e88e5;
	--badge-bg-green: #b7dfb9;
	--badge-bg-red: #ff5252;

	/* Shadows */
	--box-shadow-light: 0 4px 8px rgba(0, 0, 0, 0.1);
	--box-shadow-dark: 0 4px 8px rgba(0, 0, 0, 0.3);

	/* Transitions */
	--main-transition: all 0.3s ease-in-out;
	--main-spacing: 0.3rem;

	/*Admin Messages*/
	--admin-message-bg: #f0f9ff; /* Light blue for admin */
	--admin-message-color: #004085; /* Dark blue text for admin */
	--user-message-bg: #e8f5e9; /* Light green for user */
	--user-message-color: #1b5e20; /* Dark green text for user */
}

.submitForm:hover {
	background-color: #78ffff !important;
	color: black !important;
	transition: 0.5s;
}

.apexcharts-canvas,
.apexchartstop-5-hotels-chart,
.apexcharts-theme-light {
	text-transform: capitalize !important;
}

.sidePhoto:hover {
	transition: 0.5s;
	box-shadow:
		5px 5px 8px blue,
		10px 10px 8px #2a0000,
		15px 15px 8px white;
	min-height: 50vh;
}

.sidePhoto2:hover {
	transition: 0.5s;
	box-shadow:
		5px 5px 8px blue,
		10px 10px 8px #2a0000,
		15px 15px 8px white;
	min-height: 58vh;
}

.paragraph1:hover {
	background-color: black !important;
	transition: 0.4s;
	padding: 5px;
}

.max-height p {
	font-weight: bold;
	color: black;
	text-align: center;
}

:root {
	--primaryColor: #5fb7ea;
	--primaryRGBA: rgba(0, 0, 0, 0.3);
	--mainGrey: #fafafa;
	--darkGrey: #5f5e5e;
	--mainWhite: #fff;
	--mainBlack: #222;
	--mainTransition: all 0.3s ease-in-out;
	--mainSpacing: 0.3rem;
}

.text-title {
	/* font-family: "Montserrat", sans-serif; */
	font-family: "Helvetica";

	font-weight: bold;
	/* letter-spacing: var(--mainSpacing); */
	/* letter-spacing: 0.1rem; */
	text-transform: capitalize;
}

.text-main {
	color: var(--primaryColor);
}

.main-link {
	padding: 0.5rem 1rem;
	background: var(--primaryColor);
	color: var(--mainWhite);
	display: inline-block;
	text-transform: uppercase;
	letter-spacing: var(--mainSpacing);
	outline: 2px solid var(--primaryColor);
	outline-offset: 4px; /* you can change the value into negative but you will need to change the outline color to e.g. white*/
	transition: var(--mainTransition);
}

.main-link:hover {
	background: var(--darkGrey);
	text-decoration: none;
	color: var(--primaryColor);
	outline-color: var(--darkGrey);
}

.cart-icon {
	cursor: pointer;
}

.single-Product-Description-Style {
	font-size: 0.85rem;
	margin: none;
}

.single-Product-Description-List {
	font-size: 0.85rem;
}

::placeholder {
	font-style: italic;
	font-size: 0.75rem;
	font-weight: bold;
	color: #d3d3d3 !important;
}

.continueShoppingEmpty {
	font-weight: bold;
	font-size: 1rem;
	border: 1px solid white;
	color: white;
	/* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3); */
	background-color: #350000;
	border-radius: 20px;
	padding: 5px;
	transition: var(--mainTransition);
	text-decoration: none;
	width: 30%;
	text-align: center;
}

.continueShoppingEmpty:hover {
	padding: 12px;
	transition: var(--mainTransition);
	background-color: #ffcdcd;
	color: black;
	text-decoration: none;
	text-align: center;
}

@media (max-width: 800px) {
	.continueShoppingEmpty {
		font-weight: bold;
		font-size: 0.9rem;
		border: 1px solid white;
		color: white;
		/* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3); */
		background-color: #350000;
		border-radius: 20px;
		padding: 5px;
		transition: var(--mainTransition);
		text-decoration: none;
		width: 50%;
		text-align: center;
	}

	.continueShoppingEmpty:hover {
		padding: 8px;
		transition: var(--mainTransition);
		background-color: #ffcdcd;
		color: black;
		text-decoration: none;
	}
}

.detailsAboutAppointment {
	font-weight: bold;
	font-size: 0.95rem;
	/* text-shadow: 1px 1px 4px; */
	color: #7a0909;
}

.backToAdminDashboard {
	font-weight: bold;
	font-size: 0.9rem;
	border: 1px solid white;
	color: white;
	/* box-shadow: 2px 2px 2px 2px rgba(0, 0, 0, 0.3); */
	background-color: #030561;
	border-radius: 20px;
	padding: 5px;
	transition: var(--mainTransition);
	text-decoration: none;
	width: 30%;
	text-align: center;
}

.backToAdminDashboard:hover {
	padding: 8px;
	transition: var(--mainTransition);
	background-color: #ffcdcd;
	color: black;
	text-decoration: none;
	text-align: center;
}

.DarkbackgroundForSidebar {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 0px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1200px;
}

.DarkbackgroundForSidebar2 {
	background-color: rgba(0, 0, 0, 0.3);
	height: 100%;
	top: 0px;
	left: 0;
	position: fixed;
	z-index: 100;
	padding: 1200px;
}

.arabicFonts {
	font-family: "Droid Arabic Kufi";
}
